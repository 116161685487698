import React from "react"
import "../assets/scss/app.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import HomeLayout from "../components/HomeLayout"

const Error = () => {
  return (
    <HomeLayout title={"Błąd"}>
      <h1>404</h1>
      <h3>strona nie znaleziona</h3>
    </HomeLayout>
  )
}

export default Error
