import React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import Navbar from "./Navbar"
import { Container } from "react-bootstrap"
import "../assets/scss/app.scss"

const HomeLayout = ({ title, children }) => {
  return (
    <div className={"home d-flex flex-column "}>
      <Helmet>
        <title>{title} | Inwestprojekt</title>
      </Helmet>
      <StaticImage
        src={"../assets/images/home.jpg"}
        alt={"budynek"}
        placeholder={"blurred"}
        className={"home-cover"}
        style={{ position: "absolute" }}
      />
      <Navbar />
      <div className={"tlo cover"}>
        <Container className="d-flex flex-column align-items-center gap-2 text-banner">
          {children}
        </Container>
      </div>
    </div>
  )
}

export default HomeLayout
